import React from "react"
import { Carousel } from "react-bootstrap"

const CarouselTop = ({ slider = [] }) => {
  return (
    <>
      <Carousel fade={true}>
        {slider.map((obj, index) => (
          <Carousel.Item key={'sdf'+index}>
            <img
              className="d-block w-100"
              src={obj.slider_image.url}
              alt="First slide"
            />
            <div class="previos-button" style={{backgroundImage: `url(${index === 1 ? slider[index-1].slider_image.url:slider[slider.length-1].slider_image.url })`}}></div>
            <div class="next-button" style={{backgroundImage: `url(${index !== slider.length-1 ? slider[index+1].slider_image.url:slider[0].slider_image.url })`}}></div>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  )
}

export default CarouselTop
